import React, { useState } from 'react';
import { Modal, Alert, Spinner, Button } from 'react-bootstrap';
import config from '../../env/environment.js';
import { vehicleStatusAtom } from '../../atoms/vehicleState.js';
import { useRecoilState } from 'recoil';
import { trackEvent, AMPLITUDE_EVENTS } from '../../util/Amplitude.js';

export default function DischargeModal({ buttonLabel, buttonDisabled, powerOutage }) {
    const [showDialog, setShowDialog] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [toastText, setToastText] = useState('');
    const VIN = sessionStorage.getItem('VIN');

    const [, setVehicleStatus] = useRecoilState(vehicleStatusAtom);

    const POPUP_TEXT_STOP_TRANSFER = {
        MESSAGE: `For this session only, we will stop powering your home. You will not be able to resume powering until your next scheduled event.`,
        PROCESSING: 'Your stop request is processing',
        SUCCESS: 'Powering home has been stopped',
        FAILED: 'Sorry, your request failed. Please manually unplug or try again later.'
    };

    const POPUP_TEXT_START_TRANSFER = {
        PROCESSING: 'Your start request is processing',
        SUCCESS: 'Powering home has been started',
        FAILED: 'Sorry, your request failed. Please try again later.'
    };

    const setToastVisibility = (isInProgress, showToast, toastMessage) => {
        setShowSpinner(isInProgress);
        setShowToast(showToast);
        setToastText(toastMessage);
    };

    const sendDischargeCommand = async () => {
        let amplitudeEvent;
        let popupText;
        let dischargeUrl;
        if (buttonLabel === 'Start Transfer') {
            amplitudeEvent = AMPLITUDE_EVENTS.IP_START_TRANSFER;
            popupText = POPUP_TEXT_START_TRANSFER;
            dischargeUrl = `/energy-app/v1/start-discharging/${VIN}`;
        } else {
            amplitudeEvent = AMPLITUDE_EVENTS.IP_STOP_TRANSFER;
            popupText = POPUP_TEXT_STOP_TRANSFER;
            dischargeUrl = `/energy-app/v1/stop-discharging/${VIN}`;
        }
        trackEvent(amplitudeEvent);
        setToastVisibility(true, true, popupText.PROCESSING);
        await fetch(config.VEHICLE_STATUS_API_URL + dischargeUrl, {
            method: 'POST',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                throw new Error('Discharge command Failed');
            })
            .then((data) => {
                if (data.status === 'SUCCESS') {
                    setVehicleStatus(data.vehicleChargingStatus);
                    setToastVisibility(false, true, popupText.SUCCESS);
                    setTimeout(() => {
                        setToastVisibility(false, false, '');
                    }, 6000);
                }
            })
            .catch((error) => {
                console.error(error.message);
                setToastVisibility(false, true, popupText.FAILED);
                setTimeout(() => {
                    setToastVisibility(false, false, '');
                }, 10000);
            });
    };

    return (
        <div className="stop-transfer-container">
            <Button
                className="action-button"
                variant="secondary"
                disabled={buttonDisabled}
                onClick={() => {
                    if (!showToast) {
                        setShowDialog(true);
                    }
                }}
            >
                {buttonLabel}
            </Button>
            <Modal show={showDialog} animation={true} centered>
                <Modal.Body>
                    {buttonLabel !== 'Start Transfer' && !powerOutage && (
                        <p>{POPUP_TEXT_STOP_TRANSFER.MESSAGE}</p>
                    )}
                    <p>Are you sure you want to proceed?</p>
                    <div className="d-flex justify-content-around my-3">
                        <Button
                            className="option-button"
                            variant="light"
                            onClick={() => {
                                setShowDialog(false);
                                sendDischargeCommand();
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            className="option-button"
                            variant="light"
                            onClick={() => setShowDialog(false)}
                        >
                            No
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Alert className="alert-bottom" show={showToast}>
                <div className="d-flex justify-content-center">
                    {showSpinner && (
                        <Spinner className="my-auto mr-2" animation="border" size="sm" />
                    )}
                    <span className="">{toastText}</span>
                </div>
            </Alert>
        </div>
    );
}
