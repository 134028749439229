import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoute = () => {
    const authenticated = sessionStorage.getItem('FMA-Authentication');

    if (authenticated === 'inProgress') {
        return <Navigate to="/auth" />;
    } else if (authenticated === 'authenticated') {
        return <Outlet />;
    } else {
        return <Navigate to="/welcome" />;
    }
};
export default PrivateRoute;
