import React, { useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import MenuButtonIcon from '../../assets/icons/SIDE-MENU/Menu_icon.svg';
import SettingsIcon from '../../assets/icons/SIDE-MENU/Settings_icon.svg';
import EmailIcon from '../../assets/icons/Email_icon.svg';
import SignoutIcon from '../../assets/icons/SIDE-MENU/Sign_out_icon.svg';
import HelpCenterIcon from '../../assets/icons/Help_center_icon.svg';
import { userStateAtom } from '../../atoms/userState';
import { useRecoilValue } from 'recoil';
import Logout from '../../util/Logout';
import './SideMenu.scss';
import UserType from '../../constants/UserTypeConst';

export default function SideMenu() {
    const [show, setShow] = useState(false);
    const userData = useRecoilValue(userStateAtom);
    const isGridUser =
        userData.userType === UserType.GRID || userData.userType === UserType.GRID_BPT;
    const appVersion = require('../../../package.json').version;

    return (
        <>
            <Button className="menu-toggle-button" variant="" onClick={() => setShow(true)}>
                <div>
                    <img src={MenuButtonIcon} className="menu-button-icon" alt="Menu Toggle" />
                </div>
            </Button>
            <Offcanvas show={show} onHide={() => setShow(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Hello {userData.firstName}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Stack>
                        {isGridUser && (
                            <div className="menu-item">
                                <a
                                    className="menu-item__text"
                                    href={`https://www.ford.com/grid/${userData.programs.utility}/dashboard`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        className="menu-item__image"
                                        src={SettingsIcon}
                                        alt="Settings Icon"
                                    />
                                    SmartGrid Rewards
                                </a>
                            </div>
                        )}
                        <div className="menu-item">
                            <a
                                className="menu-item__text"
                                href="mailto:myenergy@ford.com"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img
                                    className="menu-item__image p-1.5"
                                    src={EmailIcon}
                                    alt="Email Icon"
                                />
                                Feedback
                            </a>
                        </div>
                        {!isGridUser && (
                            <div className="menu-item">
                                <a
                                    className="menu-item__text"
                                    href={`https://www.ford.com/support/how-tos/electric-vehicles/home-charging/f-150-lightning-intelligent-power-faqs/`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        className="menu-item__image p-2"
                                        src={HelpCenterIcon}
                                        alt="Help Center Icon"
                                    />
                                    Help Center
                                </a>
                            </div>
                        )}
                        <hr className="side-menu-divider"></hr>
                        <div className="menu-item" onClick={Logout}>
                            <img
                                className="menu-item__image"
                                src={SignoutIcon}
                                alt="Signout Icon"
                            />
                            Sign Out
                        </div>
                    </Stack>
                </Offcanvas.Body>
                <span className="version-number">Version: {appVersion}</span>
            </Offcanvas>
        </>
    );
}
