import config from '../env/environment.js';

const FMA = config.FMA;

export default function loadFMAScript() {
    const scriptUrl = FMA.FMA_URL + 'etc/fd/fma/bundle.js';
    const js = document.createElement('script');
    js.setAttribute('src', scriptUrl);
    js.setAttribute('data-fma-script', '');
    js.setAttribute('data-client-id', FMA.AZURE_B2C_ID);
    js.setAttribute('data-app-id', FMA.APP_ID);
    js.setAttribute('data-redirect-url', FMA.REDIRECT_URL);
    js.setAttribute('data-logout-url', FMA.LOGOUT_URL);
    js.setAttribute('data-property-key', FMA.PROPERTY_SPECIFIC_CONTENT_KEY);
    js.setAttribute('data-lang', FMA.LANG);
    js.setAttribute('data-enable-guest-guid', 'false');
    document.getElementsByTagName('head')[0].appendChild(js);
}
