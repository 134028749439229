import React, { useState } from 'react';
import { Modal, Alert, Spinner, Button } from 'react-bootstrap';
import config from '../../env/environment.js';
import moment from 'moment';
import {
    chargeDoneTimeAtom,
    energyScheduleAtom,
    scheduleStateAtom,
    vehicleStatusAtom
} from '../../atoms/vehicleState.js';
import { useRecoilState } from 'recoil';
import { trackEvent, AMPLITUDE_EVENTS } from '../../util/Amplitude.js';

export default function SkipScheduleModal({
    buttonLabel,
    buttonDisabled,
    scheduleCalculated,
    setScheduleCalculated
}) {
    const [showDialog, setShowDialog] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    const [toastText, setToastText] = useState(false);
    const VIN = sessionStorage.getItem('VIN');

    const [, setVehicleStatus] = useRecoilState(vehicleStatusAtom);
    const [, setVehicleChargeDoneTime] = useRecoilState(chargeDoneTimeAtom);
    const [, setEnergySchedule] = useRecoilState(energyScheduleAtom);
    const [, setScheduleState] = useRecoilState(scheduleStateAtom);

    const POPUP_TEXT_GRID = {
        MESSAGE: `For this charge session only, we will skip your SmartGrid schedule and charge straight through to your target.`,
        PROCESSING: 'Your skip request is processing',
        SUCCESS: 'Schedule successfully skipped',
        FAILED: 'Sorry, we weren’t able to process your skip request. Please use FordPass to manage your charge session.'
    };

    const skipSchedule = async () => {
        trackEvent(AMPLITUDE_EVENTS.SKIP_SCHEDULE);
        setToastText(POPUP_TEXT_GRID.PROCESSING);
        setShowToast(true);
        await fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v1/schedule/${VIN}`, {
            method: 'POST',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                throw new Error('Skip Schedule Request Failed');
            })
            .then((data) => {
                if (data.status === 'SUCCESS') {
                    setShowSpinner(false);
                    setScheduleCalculated(false);
                    setVehicleStatus(data.vehicleChargingStatus);
                    setVehicleChargeDoneTime(moment(data.chargeCompletionTime).calendar());
                    setEnergySchedule(data.schedule?.chargeWindows);
                    setScheduleState(data.schedule?.state);
                    setToastText(POPUP_TEXT_GRID.SUCCESS);
                    setTimeout(() => {
                        setShowToast(false);
                    }, 6000);
                }
            })
            .catch((error) => {
                console.error(error.message);
                setShowSpinner(false);
                setToastText(POPUP_TEXT_GRID.FAILED);
                setTimeout(() => {
                    setShowToast(false);
                }, 10000);
            });
    };

    return (
        <div className="skip-schedule-container">
            <Button
                className="action-button"
                variant="secondary"
                disabled={buttonDisabled || !scheduleCalculated}
                onClick={() => setShowDialog(true)}
            >
                {buttonLabel}
            </Button>
            <Modal show={showDialog} animation={true} centered>
                <Modal.Body>
                    <p>{POPUP_TEXT_GRID.MESSAGE}</p>
                    <p>Are you sure you want to proceed?</p>
                    <div className="d-flex justify-content-around my-3">
                        <Button
                            className="option-button"
                            variant="light"
                            onClick={() => {
                                setShowDialog(false);
                                skipSchedule();
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            className="option-button"
                            variant="light"
                            onClick={() => setShowDialog(false)}
                        >
                            No
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Alert className="alert-bottom" show={showToast}>
                <div className="d-flex justify-content-center">
                    {showSpinner && (
                        <Spinner className="my-auto mr-2" animation="border" size="sm" />
                    )}
                    <span className="">{toastText}</span>
                </div>
            </Alert>
        </div>
    );
}
