import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

export default function Auth() {
    const navigate = useNavigate();
    const authState = sessionStorage.getItem('FMA-Authentication');

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/welcome');
        }, 10000); // redirect if no FMA redirect after 10s

        const handleAuthEvent = () => {
            sessionStorage.setItem('FMA-Authentication', 'authenticated');
            sessionStorage.setItem('catToken', window.fma.CATBundle.access_token);
            document.body.removeEventListener('fma_authenticated', handleAuthEvent);
            navigate('/');
        };

        if (!window.fma || !window.fma.isAuthenticated) {
            document.body.addEventListener('fma_authenticated', () => {
                clearTimeout(timer);
                handleAuthEvent();
            });
        }

        return () => {
            document.body.removeEventListener('fma_authenticated', handleAuthEvent);
            clearTimeout(timer);
        };
    }, []);

    if (authState === 'inProgress') {
        return <LoadingScreen text="Connecting..." />;
    } else if (authState === 'authenticated') {
        return <Navigate to="/" />;
    } else {
        return <Navigate to="/welcome" />;
    }
}
