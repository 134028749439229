const VEHICLE_STATUS = {
    Error: 'Error',
    Charging: 'Charging',
    NotPluggedIn: 'Not Plugged In',
    ReadyToGo: 'Ready to go',
    Waiting: 'Waiting',
    WaitingToCharge: 'Waiting to Charge',
    PoweringHome: 'Powering Home',
    OutageDetected: 'Outage Detected'
};

export default VEHICLE_STATUS;
