import React from 'react';
import './PrivacyLinks.scss';
import { useRecoilValue } from 'recoil';
import { fetchLoadingAtom } from '../../atoms/vehicleState.js';

export default function PrivacyLinks() {
    const loading = useRecoilValue(fetchLoadingAtom);

    if (!loading) {
        return (
            <div className="privacy-links">
                <a href="https://www.ford.com/help/terms/">Terms & Conditions</a>
                <a href="https://www.ford.com/help/privacy/">Privacy Notice</a>
                <a href="https://www.ford.com/help/privacy/ccpa/">Your Privacy Choices</a>
            </div>
        );
    }
}
