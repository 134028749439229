import { Button, Modal, Stack, Tab, Tabs } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import './EditSchedule.scss';
import BackButton from '../../assets/icons/Back_Arrow_Left_icon.svg';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    onboardingPageCurrentlyShownAtom,
    onboardingPreviousPageAtom,
    onboardingScheduleAtom
} from '../../atoms/vehicleState.js';
import { userStateAtom } from '../../atoms/userState.js';
import OnBoardingPagesConst from '../../constants/OnBoardingPagesConst.js';
import { useLocation } from 'react-router-dom';
import { useBackButtonHandler } from '../OnBoardingContainer/UseBackButtonHandler.jsx';
import { AMPLITUDE_EVENTS, trackEvent } from '../../util/Amplitude.js';

export default function EditSchedule() {
    const [timezone, setTimezone] = useState('');
    const [isWeekendScheduleEnabled, setIsWeekendScheduleEnabled] = useState();
    const customerState = useRecoilValue(userStateAtom);
    const [onboardingPreviousPage, setOnboardingPreviousPage] = useRecoilState(
        onboardingPreviousPageAtom
    );
    const [, setOnboardingPageCurrentlyShown] = useRecoilState(onboardingPageCurrentlyShownAtom);
    const [showEditScheduleConfirmation, setShowEditScheduleConfirmation] = useState(false);
    const [schedule, setSchedule] = useRecoilState(onboardingScheduleAtom);

    const [weekdayStartTime, setWeekdayStartTime] = useState();
    const [weekdayEndTime, setWeekdayEndTime] = useState();
    const [weekendStartTime, setWeekendStartTime] = useState();
    const [weekendEndTime, setWeekendEndTime] = useState();

    const [displayErrorText, setDisplayErrorText] = useState({
        weekdayError: false,
        weekdayNoValueError: false,
        weekendError: false,
        weekendNoValueError: false
    });

    const location = useLocation();

    useEffect(() => {
        getLocationAndTimezone();
        determineIfTimePickersHaveAValue();
    }, []);

    useBackButtonHandler(location, goBackToPreviousPage);

    function goBackToPreviousPage() {
        if (onboardingPreviousPage === OnBoardingPagesConst.UTILITY_PLAN) {
            setOnboardingPreviousPage(OnBoardingPagesConst.EDIT_SCHEDULE);
            setOnboardingPageCurrentlyShown(OnBoardingPagesConst.UTILITY_PLAN);
        } else if (onboardingPreviousPage === OnBoardingPagesConst.ONBOARD_READY) {
            setOnboardingPreviousPage(OnBoardingPagesConst.EDIT_SCHEDULE);
            setOnboardingPageCurrentlyShown(OnBoardingPagesConst.ONBOARD_READY);
        }
    }

    function determineIfTimePickersHaveAValue() {
        //If we have been on edit schedule page previously and we have set a schedule then we should populate the time pickers with the values
        if (schedule && schedule.weekdays && schedule.weekdays.dischargeList.length > 0) {
            setWeekdayStartTime(schedule.weekdays.dischargeList[0].startTime);
            setWeekdayEndTime(schedule.weekdays.dischargeList[0].endTime);
            if (schedule.weekends.dischargeList.length > 0) {
                setIsWeekendScheduleEnabled(true);
                setWeekendStartTime(schedule.weekends.dischargeList[0].startTime);
                setWeekendEndTime(schedule.weekends.dischargeList[0].endTime);
            }
        }
    }

    function getLocationAndTimezone() {
        setTimezone(customerState.location.timeZoneOffset);
    }

    function performValidation(dayType) {
        const startTimeInput = document.getElementById(`${dayType}-startTime`);
        const endTimeInput = document.getElementById(`${dayType}-endTime`);
        let startTime;
        let endTime;

        if (dayType === 'weekday') {
            startTime = new Date(`1970-01-01T${weekdayStartTime}Z`);
            endTime = new Date(`1970-01-01T${weekdayEndTime}Z`);
        } else if (dayType === 'weekend') {
            startTime = new Date(`1970-01-01T${weekendStartTime}Z`);
            endTime = new Date(`1970-01-01T${weekendEndTime}Z`);
        }

        if (startTime >= endTime) {
            //Start time is after the end time
            startTimeInput.classList.add('invalid-input');
            endTimeInput.classList.add('invalid-input');
            if (dayType === 'weekday') {
                setDisplayErrorText((prevState) => ({
                    ...prevState,
                    weekdayError: true
                }));
            } else if (dayType === 'weekend') {
                setDisplayErrorText((prevState) => ({
                    ...prevState,
                    weekendError: true
                }));
            }
        } else {
            startTimeInput.classList.remove('invalid-input');
            endTimeInput.classList.remove('invalid-input');
            if (dayType === 'weekday') {
                setDisplayErrorText((prevState) => ({
                    ...prevState,
                    weekdayError: false
                }));
            } else if (dayType === 'weekend') {
                setDisplayErrorText((prevState) => ({
                    ...prevState,
                    weekendError: false
                }));
            }
        }
    }

    function verifyIfThereIsAnErrorOnPage() {
        let weekdayError = displayErrorText.weekdayError;
        let weekendError = displayErrorText.weekendError;
        let weekdayNoValueError = displayErrorText.weekdayNoValueError;
        let weekendNoValueError = displayErrorText.weekendNoValueError;

        if ((!weekendStartTime || !weekendEndTime) && isWeekendScheduleEnabled) {
            weekendError = true;
            weekendNoValueError = true;
        } else if (!isWeekendScheduleEnabled) {
            weekendError = false;
            weekendNoValueError = false;
        } else if (displayErrorText.weekendNoValueError) {
            weekendNoValueError = false;
        }

        if (!weekdayStartTime || !weekdayEndTime) {
            weekdayError = true;
            weekdayNoValueError = true;
        } else if (displayErrorText.weekdayNoValueError) {
            weekdayNoValueError = false;
        }

        if (!weekdayError && (!weekendError || !isWeekendScheduleEnabled)) {
            const weekdayDischargeList = {
                startTime: weekdayStartTime,
                endTime: weekdayEndTime
            };

            let weekendDischargeList;
            let weekendSchedule = {
                dischargeList: []
            };

            if (isWeekendScheduleEnabled) {
                weekendDischargeList = {
                    startTime: weekendStartTime,
                    endTime: weekendEndTime
                };
                weekendSchedule = {
                    dischargeList: [weekendDischargeList]
                };
            }

            setSchedule({
                utilityId: '',
                planId: '',
                weekdays: {
                    dischargeList: [weekdayDischargeList]
                },
                weekends: weekendSchedule
            });

            setShowEditScheduleConfirmation(false);
            setOnboardingPreviousPage(OnBoardingPagesConst.EDIT_SCHEDULE);
            setOnboardingPageCurrentlyShown(OnBoardingPagesConst.ONBOARD_READY);
        } else {
            setDisplayErrorText((prevState) => ({
                ...prevState,
                weekdayError: weekdayError,
                weekdayNoValueError: weekdayNoValueError,
                weekendError: weekendError,
                weekendNoValueError: weekendNoValueError
            }));
            setShowEditScheduleConfirmation(true);
        }
    }

    function renderModalErrorText() {
        const isErrorPresent =
            displayErrorText.weekdayError ||
            displayErrorText.weekdayNoValueError ||
            displayErrorText.weekendError ||
            displayErrorText.weekendNoValueError;

        return (
            <>
                {displayErrorText.weekdayError && (
                    <p className="modal-weekday-text">
                        There is an issue with your weekday schedule
                    </p>
                )}
                {displayErrorText.weekdayNoValueError && (
                    <p className="modal-weekday-notext">Please select a weekday schedule</p>
                )}
                {displayErrorText.weekendError && (
                    <p className="modal-weekend-text">
                        There is an issue with your weekend schedule
                    </p>
                )}
                {displayErrorText.weekendNoValueError && (
                    <p className="modal-weekend-notext">
                        Please either select a value in your weekend schedule or disable it
                    </p>
                )}
                {isErrorPresent && (
                    <p className="modal-error-text">Please fix the errors before saving</p>
                )}
            </>
        );
    }

    function renderUserInstruction(dayType) {
        let displayErrorMessage = false;

        if (dayType === 'weekday') {
            if (displayErrorText.weekdayError && !displayErrorText.weekdayNoValueError) {
                displayErrorMessage = true;
            }
        } else if (dayType === 'weekend') {
            if (displayErrorText.weekendError && !displayErrorText.weekendNoValueError) {
                displayErrorMessage = true;
            }
        }

        let lastThreeChars = dayType.slice(-3);
        if (displayErrorMessage) {
            return (
                <small
                    className={`error-message-week${lastThreeChars}`}
                    data-testid={`error-message-week${lastThreeChars}`}
                >
                    The end time must be after the start time
                </small>
            );
        } else {
            return (
                <small
                    className={`user-instruction-week${lastThreeChars}`}
                    data-testid={`user-instruction-week${lastThreeChars}`}
                >
                    every {dayType}
                </small>
            );
        }
    }

    const handleTabSelect = (key) => {
        trackEvent(AMPLITUDE_EVENTS.IP_EDIT_SCHEDULE_TAB_SWITCH, {
            Timestamp: new Date().toISOString(),
            tab: key
        });
    };

    return (
        <div>
            <div className="edit-schedule">
                <div className="p-3">
                    <button
                        className="arrow-button back"
                        onClick={() => {
                            trackEvent(AMPLITUDE_EVENTS.IP_EDIT_SCHEDULE_BACK);
                            setOnboardingPreviousPage(OnBoardingPagesConst.EDIT_SCHEDULE);
                            setOnboardingPageCurrentlyShown(OnBoardingPagesConst.UTILITY_PLAN);
                        }}
                    >
                        <img src={BackButton} alt="Back Button" />
                    </button>
                    <button
                        className="arrow-button forward"
                        onClick={() => {
                            trackEvent(AMPLITUDE_EVENTS.IP_EDIT_SCHEDULE_FORWARD);
                            verifyIfThereIsAnErrorOnPage();
                        }}
                    >
                        <img src={BackButton} alt="Forward Button" />
                    </button>
                    <p className="title">Your Energy Schedule</p>
                    <p className="status-text">
                        When plugged in, your F-150 Lightning will automatically generate a
                        personalized daily Intelligent Power schedule within these windows.
                    </p>
                    <div>
                        <Tabs
                            defaultActiveKey="Weekday"
                            id="fill-tab"
                            className="info-tabs mb-3"
                            fill
                            onSelect={handleTabSelect}
                        >
                            <Tab eventKey="Weekday" title="Weekday">
                                <div className="time-picker">
                                    <div className="time-picker-input-fields">
                                        <input
                                            className="start-time"
                                            type="time"
                                            id="weekday-startTime"
                                            name="weekday-startTime"
                                            min="00:00"
                                            max="23:59"
                                            data-testid="weekday-startTime"
                                            value={weekdayStartTime}
                                            onChange={(e) => {
                                                setWeekdayStartTime(e.target.value);
                                            }}
                                            onBlur={(e) => {
                                                performValidation('weekday');
                                            }}
                                        />
                                        -
                                        <input
                                            className="end-time"
                                            type="time"
                                            id="weekday-endTime"
                                            name="weekday-endTime"
                                            min="00:01"
                                            max="23:59"
                                            data-testid="weekday-endTime"
                                            value={weekdayEndTime}
                                            onChange={(e) => {
                                                setWeekdayEndTime(e.target.value);
                                            }}
                                            onBlur={(e) => {
                                                performValidation('weekday');
                                            }}
                                        />
                                    </div>
                                    <div className="error-messages-container">
                                        {renderUserInstruction('weekday')}
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Weekend" title="Weekend">
                                <div className="weekend-schedule-toggle-container">
                                    <span>Enable Weekend Schedule</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            data-testid="weekend-toggle"
                                            checked={isWeekendScheduleEnabled}
                                            onChange={() => {
                                                trackEvent(
                                                    AMPLITUDE_EVENTS.IP_EDIT_SCHEDULE_WEEKEND_TOGGLE,
                                                    {
                                                        Timestamp: new Date().toISOString(),
                                                        toggle: !isWeekendScheduleEnabled
                                                    }
                                                );
                                                setIsWeekendScheduleEnabled(
                                                    !isWeekendScheduleEnabled
                                                );
                                                performValidation('weekend');
                                            }}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div
                                    className={`time-picker weekend ${isWeekendScheduleEnabled ? '' : 'hide'}`}
                                >
                                    <div className="time-picker-input-fields">
                                        <input
                                            className="start-time"
                                            type="time"
                                            id="weekend-startTime"
                                            name="weekend-startTime"
                                            min="00:00"
                                            max="23:59"
                                            data-testid="weekend-startTime"
                                            value={weekendStartTime}
                                            onChange={(e) => {
                                                setWeekendStartTime(e.target.value);
                                            }}
                                            onBlur={(e) => {
                                                performValidation('weekend');
                                            }}
                                        />
                                        -
                                        <input
                                            className="end-time"
                                            type="time"
                                            id="weekend-endTime"
                                            name="weekemd-endTime"
                                            min="00:01"
                                            max="23:59"
                                            data-testid="weekend-endTime"
                                            value={weekendEndTime}
                                            onChange={(e) => {
                                                setWeekendEndTime(e.target.value);
                                            }}
                                            onBlur={(e) => {
                                                performValidation('weekend');
                                            }}
                                        />
                                    </div>
                                    <div className="error-messages-container">
                                        {renderUserInstruction('weekend')}
                                    </div>
                                    <div className="border-line"></div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    <div className="d-inline-block justify-content-center pct-disclaimer">
                        <p>
                            Please set your energy schedule for your high cost time of use windows.
                            For EAP, please continue to set your charging times in FordPass at this
                            time.
                        </p>
                    </div>
                    <footer className="footer">
                        <Stack direction="horizontal" gap={2}>
                            <div className="text-row-2 left">Time Zone</div>
                            <div className="text-row-2 right">{timezone}</div>
                        </Stack>
                    </footer>
                </div>
            </div>
            <Modal
                show={showEditScheduleConfirmation}
                animation={true}
                centered
                className="edit-schedule-confirmation-modal"
            >
                <Modal.Body>
                    <div className="d-flex justify-content-around my-3">
                        <Button
                            className="btn-close btn-close-white"
                            onClick={() => setShowEditScheduleConfirmation(false)}
                        ></Button>
                    </div>
                    <div>{renderModalErrorText()}</div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
