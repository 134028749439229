import './styles.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import HomePage from './pages/HomePage/HomePage';
import Login from './pages/Login/Login';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import PrivateRoute from './util/PrivateRoute';
import Auth from './pages/Login/Auth';
import loadFMAScript from './util/FmaAuth';
import { initAmplitude } from './util/Amplitude';
const root = ReactDOM.createRoot(document.getElementById('root'));
loadFMAScript();
initAmplitude();

async function main() {
    root.render(
        <RecoilRoot>
            <Router>
                <Routes>
                    <Route element={<PrivateRoute />}>
                        <Route exact path="/" element={<HomePage />} />
                        <Route path="/error" element={<ErrorPage />} />
                    </Route>
                    <Route path="/welcome" element={<Login />} />
                    <Route path="/auth" element={<Auth />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Router>
        </RecoilRoot>
    );
}
main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
