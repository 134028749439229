import React, { useEffect, useState } from 'react';
import './BackupPowerTile.scss';
import DischargeModal from '../DischargeModal/DischargeModal.jsx';
import config from '../../env/environment';
import { useRecoilValue } from 'recoil';
import { Card, Stack } from 'react-bootstrap';
import { fetchLoadingAtom, vehicleStateAtom } from '../../atoms/vehicleState';
import userTypeConst from '../../constants/UserTypeConst.js';
import ChargeModal from '../ChargeModal/ChargeModal.jsx';

export default function BackupPowerTile({ userType }) {
    const VIN = sessionStorage.getItem('VIN');
    const loading = useRecoilValue(fetchLoadingAtom);
    const vehicleState = useRecoilValue(vehicleStateAtom);
    const [intelligentBackupPower, setIntelligentBackupPower] = useState({});

    const getIntelligentBackupPower = () => {
        fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v1/intelligent-backup-power/${VIN}`, {
            method: 'GET',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
        })
            .then((response) => response.json())
            .then((data) => {
                setIntelligentBackupPower(data);
            })
            .catch((error) => {
                console.error('Error getting IntelligentBackupPower ', error);
            });
    };

    const showBackupPowerTile = () => {
        return (
            intelligentBackupPower.reserve !== undefined &&
            intelligentBackupPower.reserve !== null &&
            intelligentBackupPower.reserve !== 0
        );
    };

    useEffect(() => {
        getIntelligentBackupPower();
        const interval = setInterval(() => {
            getIntelligentBackupPower();
        }, 20000);
        return () => clearInterval(interval);
    }, []);

    if (!loading && showBackupPowerTile()) {
        return (
            <div className="backup-power-tile">
                <Card className="rounded-card col-card">
                    <Stack direction="horizontal" className="p-1" gap={3}>
                        <Stack direction="vertical" className="backup-power-section">
                            <div className="backup-power-tile-header">Intelligent Backup Power</div>
                            <div className="text-row-2 w-45">
                                {intelligentBackupPower.reserve} mi
                            </div>
                            <div className="text-row-3 w-45">
                                {intelligentBackupPower.reserveLabel}
                            </div>
                        </Stack>
                        {vehicleState.chargeModal.buttonVisible &&
                            userType === userTypeConst.BPT && (
                                <ChargeModal
                                    buttonLabel={vehicleState.chargeModal.buttonLabel}
                                    buttonDisabled={vehicleState.chargeModal.buttonDisabled}
                                    userType={userType}
                                    scheduleCalculated={true} //BPT button doesn't have a schedule, chargeModal will disable schedule if false though and we don't want that to happen
                                    data-testid="chargemodal-component"
                                />
                            )}
                        {vehicleState.dischargeModal.buttonVisible && (
                            <DischargeModal
                                buttonLabel={vehicleState.dischargeModal.buttonLabel}
                                buttonDisabled={vehicleState.dischargeModal.buttonDisabled}
                                powerOutage={vehicleState.powerOutage}
                                data-testid="dischargemodal-component"
                            />
                        )}
                    </Stack>
                </Card>
            </div>
        );
    }
}
