import React from 'react';
import PrivacyLinks from '../PrivacyLinks/PrivacyLinks.jsx';
import EditSchedule from '../EditSchedule/EditSchedule.jsx';
import OnBoardReady from '../OnBoardReady/OnBoardReady.jsx';
import OnBoardingPagesConst from '../../constants/OnBoardingPagesConst.js';
import UtilityPlanSelector from '../UtilityPlanSelector/UtilityPlanSelector.jsx';
import { onboardingPageCurrentlyShownAtom } from '../../atoms/vehicleState.js';
import './OnBoardingContainer.scss';
import { useRecoilValue } from 'recoil';

export default function OnBoardingContainer({ refreshStatus }) {
    const onboardingPageCurrentlyShown = useRecoilValue(onboardingPageCurrentlyShownAtom);

    return (
        <div
            className={`onboarding-container ${onboardingPageCurrentlyShown === OnBoardingPagesConst.ONBOARD_READY ? 'on-board-ready' : ''}`}
        >
            {onboardingPageCurrentlyShown === OnBoardingPagesConst.UTILITY_PLAN && (
                <UtilityPlanSelector data-testid="utilityPlanSelector-component" />
            )}
            {onboardingPageCurrentlyShown === OnBoardingPagesConst.ONBOARD_READY && (
                <OnBoardReady
                    refreshCustomerStatus={refreshStatus}
                    data-testid="onboardyready-component"
                />
            )}
            {onboardingPageCurrentlyShown === OnBoardingPagesConst.EDIT_SCHEDULE && (
                <EditSchedule data-testid="editSchedule-component" />
            )}
            <PrivacyLinks />
        </div>
    );
}
