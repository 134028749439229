import React, { useEffect, useState } from 'react';
import './HomePage.scss';
import SummaryHero from '../../components/SummaryHero/SummaryHero';
import ChargeSettings from '../../components/ChargeSettings/ChargeSettings';
import BackupPowerTile from '../../components/BackupPowerTile/BackupPowerTile';
import UserType from '../../constants/UserTypeConst';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loginStartTimeAtom, userStateAtom } from '../../atoms/userState';
import ERROR_STATUS from '../../constants/ErrorStatusConst';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import config from '../../env/environment.js';
import { trackEvent, AMPLITUDE_EVENTS, identifyUser } from '../../util/Amplitude';
import OnBoardingPage from '../IntelligentPower/OnBoardingPage';
import ChargeInformation from '../../components/ChargeInformation/ChargeInformation.jsx';
import PrivacyLinks from '../../components/PrivacyLinks/PrivacyLinks.jsx';
import { fetchLoadingAtom } from '../../atoms/vehicleState.js';

const HomePage = () => {
    const [loading, setLoading] = useState(true);
    const [, setVehicleLoading] = useRecoilState(fetchLoadingAtom);
    const authState = sessionStorage.getItem('FMA-Authentication');
    const [userState, setUserState] = useRecoilState(userStateAtom);
    const [, setLoginStartTime] = useRecoilState(loginStartTimeAtom);
    const navigate = useNavigate();

    const getCustomerInfo = () => {
        fetch(config.VEHICLE_STATUS_API_URL + '/energy-app/v1/customer', {
            method: 'GET',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 400) {
                    throw new Error(ERROR_STATUS.NotGridCustomer);
                } else {
                    throw new Error(response);
                }
            })
            .then((data) => {
                if (data.vin) {
                    setUserState(data);
                    sessionStorage.setItem('user', JSON.stringify(data));
                    sessionStorage.setItem('VIN', data.vin);
                    identifyUser(data.vin, data.email, data.userType);
                    trackEvent(AMPLITUDE_EVENTS.LOGIN_SUCCESS, {
                        Timestamp: new Date().toISOString(),
                        Email: data.email,
                        VIN: data.vin
                    });

                    setLoading(false);
                } else if (data.error === 'Unauthorized') {
                    navigate('/welcome');
                } else {
                    setUserState(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (error.message === ERROR_STATUS.NotGridCustomer) {
                    trackEvent(AMPLITUDE_EVENTS.LOGIN_FAILED);
                    navigate('/error', { state: ERROR_STATUS.NotGridCustomer });
                } else {
                    navigate('/error', { state: ERROR_STATUS.Unexpected });
                }
            });
    };

    useEffect(() => {
        refreshCustomerStatus();
    }, []);

    const refreshCustomerStatus = () => {
        setLoading(true);
        setVehicleLoading(true);
        setLoginStartTime(new Date().getTime());
        getCustomerInfo();
    };

    if (authState === 'authenticated') {
        if (loading) {
            return <LoadingScreen text="Verifying..." />;
        } else if (userState.customerStatus === 'HOME') {
            return (
                <>
                    <SummaryHero
                        data-testid="summary-hero-component"
                        userType={userState.userType}
                        vehicleImage={userState.vehicleImage}
                    />
                    <ChargeSettings data-testid="charge-settings-component" />
                    {userState.userType !== UserType.GRID && (
                        <BackupPowerTile
                            data-testid="backup-power-component"
                            userType={userState.userType}
                        />
                    )}
                    <ChargeInformation
                        data-test-id="charge-information-component"
                        userType={userState.userType}
                    />
                    <PrivacyLinks data-test-id="privacy-links" />
                </>
            );
        } else {
            return (
                <OnBoardingPage
                    onboardStatus={userState.customerStatus}
                    refreshStatus={refreshCustomerStatus}
                />
            );
        }
    }
};

export default HomePage;
