import { Card } from 'react-bootstrap';
import './WeeklyTransferGoal.scss';
import WeeklyTransferGoalGreyRectangle from '../../assets/icons/Weekly_transfer_goal_rectangle_grey.svg';
import WeeklyTransferGoalGreenRectangle from '../../assets/icons/Weekly_transfer_goal_rectangle_green.svg';
import React from 'react';
import {
    weeklyTransferGoal,
    weeklyTransferGoalSquareScale
} from '../../constants/WeeklyTransferGoalConst.js';

export default function WeeklyTransferGoal({ schedule }) {
    function determineNumberOfGreenRectangles() {
        return Math.floor(schedule.remainingTransferLimit / weeklyTransferGoalSquareScale);
    }

    const numberOfGreenRectangles = determineNumberOfGreenRectangles();
    return (
        <div className="weekly-transfer-goal">
            <Card className="rounded-card timeline-card">
                <Card.Body>
                    <div className="weekly-transfer-goal-title">Weekly Transfer Goal</div>
                    <div className="weekly-transfer-goal-container">
                        <div className="weekly-transfer-goal-progress-bar">
                            {[...Array(10)].map((_, i) => (
                                <img
                                    key={i}
                                    src={
                                        i < numberOfGreenRectangles
                                            ? WeeklyTransferGoalGreenRectangle
                                            : WeeklyTransferGoalGreyRectangle
                                    }
                                />
                            ))}
                        </div>
                        <div className="weekly-transfer-goal-label">
                            {schedule.remainingTransferLimit} kWh / {weeklyTransferGoal} kWh
                        </div>
                    </div>
                    <div className="weekly-transfer-goal-instruction-text">
                        <p>Plug in when you’re home to save more and support the grid!</p>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}
