const origin = window.location.origin;

const prod = {
    VEHICLE_STATUS_API_URL: 'https://api.pd01e.gcp.ford.com/meea-vs',
    FMA: {
        APP_ID: '68f4bbbc-e09d-43a3-95ee-9d01a2b78ac2',
        AZURE_B2C_ID: process.env.REACT_APP_FMA_API_KEY,
        REDIRECT_URL: origin + '/auth',
        LOGOUT_URL: origin + '/welcome',
        PROPERTY_SPECIFIC_CONTENT_KEY: '100831',
        LANG: 'en_us',
        FMA_URL: 'https://www.account.ford.com/'
    },
    AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY
};

const dev = {
    production: false,
    VEHICLE_STATUS_API_URL: 'http://localhost:8080',
    FMA: {
        APP_ID: '68f4bbbc-e09d-43a3-95ee-9d01a2b78ac2',
        AZURE_B2C_ID: process.env.REACT_APP_FMA_API_KEY,
        // APP_ID: 'e8922450-1fb9-4dca-9f6e-86c8bdf38feb', // pre-prod ID
        // AZURE_B2C_ID: '0c2540de-8bb6-462d-b153-247665fe37a2',
        REDIRECT_URL: origin + '/auth',
        LOGOUT_URL: origin + '/welcome',
        PROPERTY_SPECIFIC_CONTENT_KEY: '100831',
        LANG: 'en_us',
        FMA_URL: 'https://www.account.ford.com/'
        //FMA_URL: 'https://wwwqa.account.ford.com/'
    },
    AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY
};
const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;
