import { useEffect } from 'react';

export const useBackButtonHandler = (location, goBackToPreviousPage) => {
    function handlePopState(event) {
        // The back button was clicked
        goBackToPreviousPage();

        // Push the user back forward to nullify the back button action
        window.history.forward();
    }

    useEffect(() => {
        // Push a history entry
        window.history.pushState(null, document.title, location.pathname);

        // Listen for popstate events
        window.addEventListener('popstate', handlePopState);

        return () => {
            // Clean up the event listener
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);
};
