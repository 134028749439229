import React from 'react';
import ReactApexChart from 'react-apexcharts';
import blueFillImage from '../../assets/images/Timeline_fill_blue.svg';
import greenFillImage from '../../assets/images/Timeline_fill_green.svg';
import grayFillImage from '../../assets/images/Timeline_fill_gray.svg';

const FILL_COLORS = {
    IMAGES: {
        type: 'image',
        opacity: 1,
        image: {
            src: [grayFillImage, blueFillImage, greenFillImage],
            width: 600,
            height: 80
        }
    }
};

export default function TimeLine({ chargeWindowsList, scheduleState }) {
    let currentTime = new Date();
    let hour24FromNow = new Date();
    hour24FromNow.setHours(currentTime.getHours() + 24);

    let timeLineData = TrimChargeWindows(
        chargeWindowsList,
        scheduleState,
        currentTime.getTime(),
        hour24FromNow.getTime()
    );

    let chartConfig = {
        series: [
            {
                name: 'GRAY BACKGROUND',
                data: [
                    {
                        x: 'Now',
                        y: [currentTime.getTime(), hour24FromNow.getTime()],
                        type: 'GRAY BACKGROUND'
                    }
                ]
            },
            {
                name: 'CHARGE',
                data: timeLineData.filter((window) => window.type === 'CHARGE')
            },
            {
                name: 'DISCHARGE',
                data: timeLineData.filter((window) => window.type === 'DISCHARGE')
            }
        ],
        options: {
            fill: FILL_COLORS.IMAGES,
            chart: {
                type: 'rangeBar'
            },
            states: {
                active: {
                    filter: {
                        type: 'none'
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    rangeBarGroupRows: true
                }
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false, //set to local time
                    datetimeFormatter: {
                        hour: 'htt',
                        day: 'MM/dd'
                    },
                    offsetX: 11,
                    hideOverlappingLabels: true
                }
            },
            yaxis: {
                min: new Date().getTime(),
                max: hour24FromNow.getTime(),
                labels: {
                    show: false, // hide y-axis label
                    align: 'right',
                    offsetX: 10,
                    offsetY: 50,
                    rotate: 0
                }
            },
            tooltip: {
                enabled: false
            },
            axisTicks: { show: false },
            selection: {
                enabled: false
            }
        }
    };

    return (
        <div id="chart">
            <ReactApexChart
                options={chartConfig.options}
                series={chartConfig.series}
                type="rangeBar"
                height={130}
                width={700}
            />
        </div>
    );
}

export function TrimChargeWindows(windowsList, scheduleState, now, hour24) {
    let trimmedWindows = [];

    if (windowsList.length === 0 || scheduleState === 'Empty') {
        return [
            {
                x: 'Now',
                y: [now, hour24],
                type: 'EMPTY'
            }
        ];
    }
    for (let window of windowsList) {
        const start = new Date(window.startTime).getTime();
        const end = new Date(window.endTime).getTime();
        //filter out charge windows that ended before now
        if (now < end) {
            let timeSlot = [];

            if (start <= now) {
                // start before now
                timeSlot.push(now);
            } else if (now <= start && start <= hour24) {
                // start after current time
                timeSlot.push(start);
            }

            if (hour24 <= end) {
                // end after 24hours
                timeSlot.push(hour24);
            } else if (end <= hour24) {
                timeSlot.push(end);
            }

            trimmedWindows.push({
                type: window.type,
                x: 'Now',
                y: timeSlot
            });
        }
    }

    return trimmedWindows;
}
