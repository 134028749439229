import React from 'react';
import { Stack, Button } from 'react-bootstrap';
import './Login.scss';
import PrivacyLinks from '../../components/PrivacyLinks/PrivacyLinks.jsx';

export default function Login() {
    const appVersion = require('../../../package.json').version;

    function login() {
        sessionStorage.setItem('FMA-Authentication', 'inProgress');
        window.fma.login();
    }

    return (
        <div className="login-container">
            <div className="image-section">
                <img
                    className="login-background-img"
                    src={`${process.env.PUBLIC_URL}/Login_image.webp`}
                    alt="Login Background"
                />
            </div>
            <Stack className="login-section" gap={4}>
                <img
                    className="ford-logo"
                    src={`${process.env.PUBLIC_URL}/Ford_logo.svg`}
                    alt="Ford Logo"
                />
                <div className="login-heading">Welcome to Ford Energy Management</div>
                <Button className="login-button mx-auto" onClick={login}>
                    Login
                </Button>
                <div className="contact-text">
                    Unable to login? <a href="mailto:myenergy@ford.com">Contact Us.</a>
                </div>
                <PrivacyLinks data-test-id="privacy-links" />
            </Stack>
            <div className="app-version">V{appVersion}</div>
        </div>
    );
}
