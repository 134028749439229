import React from 'react';
import './EnergySchedule.scss';
import TimeLine from './TimeLine';
import SkipScheduleModal from '../SkipScheduleModal/SkipScheduleModal.jsx';
import { vehicleStateAtom } from '../../atoms/vehicleState';
import { useRecoilValue } from 'recoil';
import { Card } from 'react-bootstrap';
import VEHICLE_STATUS from '../../constants/VehicleStatusConst';
import SCHEDULE_STATUS from '../../constants/ScheduleStatusConst';
import ChargeModal from '../ChargeModal/ChargeModal.jsx';

export default function EnergySchedule({
    schedule,
    scheduleCalculated,
    setScheduleCalculated,
    userType
}) {
    const vehicleState = useRecoilValue(vehicleStateAtom);

    if (schedule.state !== SCHEDULE_STATUS.SCHEDULE_DISABLED) {
        return (
            <div className="energy-schedule">
                <Card className="rounded-card timeline-card">
                    <Card.Body>
                        <div className="timeline-title">Next 24 Hrs</div>
                        {schedule.state === VEHICLE_STATUS.NotPluggedIn ? (
                            <div className="no-schedule">
                                <span>Plug in to see your schedule</span>
                            </div>
                        ) : (
                            <>
                                <div className="timeline">
                                    <TimeLine
                                        chargeWindowsList={schedule.chargeWindows}
                                        scheduleState={schedule.state}
                                    />
                                </div>
                                <div className="schedule-buttons">
                                    {vehicleState.chargeModal.buttonVisible && (
                                        <ChargeModal
                                            buttonLabel={vehicleState.chargeModal.buttonLabel}
                                            buttonDisabled={vehicleState.chargeModal.buttonDisabled}
                                            scheduleCalculated={scheduleCalculated}
                                            setScheduleCalculated={setScheduleCalculated}
                                            userType={userType}
                                            data-testid="chargemodal-component"
                                        />
                                    )}
                                    {vehicleState.skipScheduleModal.buttonVisible && (
                                        <SkipScheduleModal
                                            buttonLabel={vehicleState.skipScheduleModal.buttonLabel}
                                            buttonDisabled={
                                                vehicleState.skipScheduleModal.buttonDisabled
                                            }
                                            scheduleCalculated={scheduleCalculated}
                                            setScheduleCalculated={setScheduleCalculated}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </Card.Body>
                </Card>
            </div>
        );
    }
}
